@use "sass:map";
@use "sass:color";
@use "sass:math" as math;
@use './breakpoints' as breakpoint;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$bbo-font-family: 'Inter', sans-serif;

// https://material.angular.io/guide/typography
// headline-1 to headline-4 are hero headers (not h1-h4) (by material design specifications)
// not used so far in the BBO store's mockups
$my-custom-typography-config: mat.define-typography-config(
    $headline-1: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 10px),
    $headline-2: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 10px),
    $headline-3: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 10px),
    $headline-4: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 48px),
    $headline-5: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 39px), // h1
    $headline-6: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 33px), // h2
    $subtitle-1: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 27px), // h3
    $subtitle-2: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 19px, $font-weight: 700), // h4
    $body-1: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 16px), // base body text
    $body-2: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 13px, $line-height: 18px), // secondary body text
    $caption: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 12px), // Smaller body and hint text.
    $button: mat.define-typography-level($font-family: $bbo-font-family, $font-size: 15px), // buttons and anchors
);

$bbo-md-primary-palette: (
    100 : #0056E0,
    500 : #2176FF,
    600 : #99C0FF,
    700 : #D6E6FF,
    contrast: (
        100 : #ffffff,
        500 : #ffffff,
        600 : #000000,
        700 : #000000,
    )
);

$bbo-md-grape-palette: (
    50  : #E8E2F4,
    100 : #D1C4E9,
    200 : #BAA6DD,
    300 : #A489D2,
    400 : #8D6BC7,
    500 : #6E45B4,
    600 : #633EA3,
    700 : #513285,
    800 : #3F2768,
    900 : #2D1C4A,
    contrast: (
        50  : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
    )
);

$bbo-md-warn-palette: (
    100 : #FF3B44,
    500 : #FF3B44,
    600 : #F05252,
    700 : #FFE0E2,
    contrast: (
        100 : #ffffff,
        500 : #ffffff,
        700 : #000000,
    )
);

$bbo-md-accent-palette: (
    100 : #FFBE0F1A,
    200 : #FFBE0F,
    300 : #FFD815,
    500 : #F7A963,
    700 : #D9B836,
    contrast: (
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        500 : #ffffff,
        700 : #ffffff,
    )
);

$bbo-md-gray-palette: (
    50  : #F9FAFB,
    100 : #F3F4F6,
    200 : #E5E7EB,
    300 : #D1D5DB,
    400 : #9CA3AF,
    500 : #6B7280,
    600 : #4B5563,
    700 : #374151,
    800 : #1F2A37,
    900 : #111928,
    contrast: (
        100 : #ffffff,
        500 : #ffffff,
        700 : #ffffff,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bbo-store-client-primary: mat.define-palette($bbo-md-primary-palette);
$bbo-store-client-accent: mat.define-palette($bbo-md-accent-palette);

$bbo-store-client-warn: mat.define-palette($bbo-md-warn-palette);

$bbo-store-client-gray: mat.define-palette($bbo-md-gray-palette);

$bbo-store-client-grape: mat.define-palette($bbo-md-grape-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bbo-store-client-theme: mat.define-light-theme((
  color: (
    primary: $bbo-store-client-primary,
    accent: $bbo-store-client-accent,
    warn: $bbo-store-client-warn,
    gray: $bbo-store-client-gray
  ),
  typography: $my-custom-typography-config
));

mat-stepper {
    // change step divider weight and color
    .mat-stepper-horizontal-line {
        border-top-width: 2px;
        border-top-color: #737373;
        // have to add 3px due to the margin 6px added for the completed step bigger style
        top: 42px !important;
    }

    mat-step-header {
        // remove the after and before small divider to get more space between divider and completed step which are larger
        &[ng-reflect-state="done"] {
            &::before,
            &::after {
                display: none !important;
            }
        }

        // change step divider weight and color
        &::before,
        &::after {
            border-top-width: 2px !important;
            border-top-color: #737373 !important;
            // have to add 3px due to the margin 6px added for the completed step bigger style
            top: 42px !important;
        }

        // remove lite grey background on over and focus
        &:hover,
        &:focus {
            background-color: transparent !important
        }

        // on hover on selected step or previous completed step apply a darker blue
        &:not([ng-reflect-state="number"]):hover {
            .mat-step-icon {
                background-color: #0056E0;
            }

            .mat-step-label {
                color: #0056E0 !important;
            }
        }

        .mat-ripple {
            display: none !important;
        }

        .mat-step-icon {
            // reserve space for bigger completed step in order to keep all align
            margin: 6px;

            // apply a specific style for done state
            &.mat-step-icon-state-done {
                border-radius: 12px;
                border: 2px;
                padding: 6px;
                margin: 0 !important;
            }

            .mat-step-icon-content {
                font-size: 13px;
            }
        }

        // prevent mat-stepper selected step to have a bigger label
        .mat-step-label-selected {
            color: #2176FF !important;
            font-size: 13px;
        }
    }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bbo-store-client-theme);

// @include mat.typography-hierarchy($my-custom-typography-config);

@mixin bbo-typography-level($level) {
    @if $level == 'headline-1' {
        @include mat.typography-level($my-custom-typography-config, 'headline-4');
    }
    @else if $level == 'headline-2' {
        @include mat.typography-level($my-custom-typography-config, 'headline-5');
    }
    @else if $level == 'headline-3' {
        @include mat.typography-level($my-custom-typography-config, 'headline-6');
    }
    @else if $level == 'headline-4' {
        @include mat.typography-level($my-custom-typography-config, 'subtitle-1');
    }
    @else if $level == 'headline-5' {
        @error "Font level #{$level} not supported";
    }
    @else if $level == 'headline-6' {
        @include mat.typography-level($my-custom-typography-config, 'subtitle-2');
    }
    @else if $level == 'body-1' {
        @include mat.typography-level($my-custom-typography-config, 'body-1');
    }
    @else if $level == 'body-2' {
        @include mat.typography-level($my-custom-typography-config, 'body-2');
    } @else {
        @error "Font level #{$level} not supported";
    }
}

.bbo-typography {
    @include bbo-typography-level('body-1');
}

.bbo-headline-1 {
    @include bbo-typography-level('headline-1');
}
.bbo-headline-2 {
    @include bbo-typography-level('headline-2');
}
.bbo-headline-3 {
    @include bbo-typography-level('headline-3');
}
.bbo-headline-4 {
    @include bbo-typography-level('headline-4');
}
.bbo-headline-6 {
    @include bbo-typography-level('headline-6');
}
.bbo-body-1 {
    @include bbo-typography-level('body-1');
}
.bbo-body-2 {
    @include bbo-typography-level('body-2');
}

@include breakpoint.sm-up() {
    .bbo-headline-1-sm-up {
        @include bbo-typography-level('headline-1');
    }
    .bbo-headline-2-sm-up {
        @include bbo-typography-level('headline-2');
    }
    .bbo-headline-3-sm-up {
        @include bbo-typography-level('headline-3');
    }
    .bbo-headline-4-sm-up {
        @include bbo-typography-level('headline-4');
    }
    .bbo-headline-6-sm-up {
        @include bbo-typography-level('headline-6');
    }
}

@include breakpoint.md-up() {
    .bbo-headline-1-md-up {
        @include bbo-typography-level('headline-1');
    }
    .bbo-headline-2-md-up {
        @include bbo-typography-level('headline-2');
    }
    .bbo-headline-3-md-up {
        @include bbo-typography-level('headline-3');
    }
    .bbo-headline-4-md-up {
        @include bbo-typography-level('headline-4');
    }
    .bbo-headline-6-md-up {
        @include bbo-typography-level('headline-6');
    }
} 

html, body { height: 100%; }

body {
    margin: 0;
    color: #191919;
}

body {
    --mdc-shape-small: 8px; // inputs
    --mdc-shape-medium: 20px; // cards
}

.color-primary {
    color: mat.get-color-from-palette($bbo-store-client-primary, default);
}
.color-accent {
    color: mat.get-color-from-palette($bbo-store-client-accent, default);
}
.color-accent-darker {
    color: mat.get-color-from-palette($bbo-store-client-accent, darker);
}
.color-warn {
    color: mat.get-color-from-palette($bbo-store-client-warn, default);
}
.color-purple {
    color: mat.get-color-from-palette($bbo-store-client-grape, default);
}
.color-gray, .color-grey {
    color: mat.get-color-from-palette($bbo-store-client-gray, default);
}

.color-gray-700 {
    color: mat.get-color-from-palette($bbo-store-client-gray, 700);
}

.color-gray-900 {
    color: mat.get-color-from-palette($bbo-store-client-gray, 900);
}

.bbo-large-line-height {
    line-height: 150%;
}

.color-coral {
    color: #FF8261;
}

.color-sunset {
    color: #FF6159;
}

mat-card {
    box-shadow: 0px 4px 20px 0px #19191926 !important;
    max-width: 648px;
}

mat-card-content.bbo-small-card:last-child {
    padding: 30px;
}

mat-card-content.bbo-medium-card:last-child {
    padding: 40px;

    @include breakpoint.md-up() {
        padding: 60px;
    }
}

.bbo-radio-button {
    --mdc-radio-selected-focus-icon-color: #2176FF !important;
    --mdc-radio-selected-hover-icon-color: #2176FF !important;
    --mdc-radio-selected-icon-color: #2176FF !important; 
    --mdc-radio-selected-pressed-icon-color: #2176FF !important;
    --mat-mdc-radio-checked-ripple-color: #2176FF !important;

    --mdc-radio-unselected-focus-icon-color: #B3B3B3;
    --mdc-radio-unselected-hover-icon-color: #B3B3B3;
    --mdc-radio-unselected-icon-color: #B3B3B3;
    --mdc-radio-unselected-pressed-icon-color: #B3B3B3;

    .mdc-radio__outer-circle {
        border-width: 1px;
    }

    &.mat-mdc-radio-checked .mdc-radio__outer-circle {
        border-width: 5px !important;
    }

    .mdc-radio__inner-circle {
        display: none !important;
    }
}

// When editing angular material's internal elements, mind that it may break 
// in next release because they explictly say that they can change it
// whenever when they want
// so try to use as much as possible the mdc variables
.bbo-button {
    $primary-button-base-color: #0056E0;
    $ghost-button-base-color: #99C0FF;
    
    $button-height: 49px;
    $button-font-size: 15px;
    $button-disabled: (
        "text-color": white,
        "background-color": #e6e6e6
    );
    $button-ghost-disabled: (
        "text-color": #b3b3b3,
        "background-color": rgba(0,0,0,0)
    );

    @mixin modifyButtonHeight($height) {
        height: #{$height}px !important;
        @if ($height > 48) {  // minimum size for touch target is 48px
                                // if wanted height is above then adapt the touch target's height
            .mat-mdc-button-touch-target {
                height: $height !important;
            }
        } @else {
            .mat-mdc-button-touch-target {
                $ratio-button-height-to-touch-target-height: math.div(48, 34); // values taken from angular material
                                                                    // default touch target height / default visible button height
                height: #{math.min(48, $height * $ratio-button-height-to-touch-target-height)}px !important;
            }
        }
    }

    @include modifyButtonHeight(49);

    &.bbo-small-button {
        @include modifyButtonHeight(34);
        @include bbo-typography-level('body-2');
    }

    font-size: $button-font-size;

    // on hover and focus states
    // focus-visible for keyboard focus (when user navigates with the TAB key)
    &.mat-mdc-unelevated-button:not(:disabled):hover,
    &.mat-mdc-unelevated-button:not(:disabled):focus-visible {
        --mat-mdc-button-persistent-ripple-color: #{$primary-button-base-color};
    }
    &.mat-mdc-button:not(:disabled):hover,
    &.mat-mdc-button:not(:disabled):focus-visible {
        --mat-mdc-button-persistent-ripple-color: #{$ghost-button-base-color};
    }
    &.mdc-button.mat-purple:not(:disabled):hover,
    &.mdc-button.mat-purple:not(:disabled):focus-visible {
        --mat-mdc-button-persistent-ripple-color: #{mat.get-color-from-palette($bbo-store-client-grape, 600)};

        &.secondary {
            --mat-mdc-button-persistent-ripple-color: #{mat.get-color-from-palette($bbo-store-client-grape, 300)};
        }
    }
    &.mdc-button.mat-coral:not(:disabled):hover,
    &.mdc-button.mat-coral:not(:disabled):focus-visible {
        --mat-mdc-button-persistent-ripple-color: #FF8261;
    }
    
    &.mdc-button.mat-warn:not(:disabled):hover,
    &.mdc-button.mat-warn:not(:disabled):focus-visible {
        --mat-mdc-button-persistent-ripple-color: #{mat.get-color-from-palette($bbo-store-client-warn, 600)};
    }
    &.mat-mdc-button.mat-warn:not(:disabled):hover,
    &.mat-mdc-button.mat-warn:not(:disabled):focus-visible {
        --mat-mdc-button-persistent-ripple-color: #{mat.get-color-from-palette($bbo-store-client-warn, darker)};
    }
    &.mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
    &.mat-mdc-unelevated-button:focus-visible .mat-mdc-button-persistent-ripple::before,
    &.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
    &.mat-mdc-button:focus-visible .mat-mdc-button-persistent-ripple::before {
        opacity: 1; // needed because the opacity of the ripple counts for the color on hover
    }
    // end on hover and focus states

    // $bbo-md-gray-purple

    // default state
    &.mat-mdc-unelevated-button:not(:disabled) {
        background: linear-gradient(180deg, mat.get-color-from-palette($bbo-store-client-primary, default) 0%, $primary-button-base-color 100%);
        // we cannot use a variable there because the framework only the variable for the css property background-color
    }
    &.mat-mdc-unelevated-button.mat-purple:not(:disabled) {
        --mdc-filled-button-label-text-color: #ffffff;
        background: linear-gradient(180deg, mat.get-color-from-palette($bbo-store-client-grape, 400) 0%, mat.get-color-from-palette($bbo-store-client-grape, 600) 100%);

        &.secondary {
            color: #1F2A37;
            background: mat.get-color-from-palette($bbo-store-client-grape, 100);
        }
    }

    &.mat-mdc-unelevated-button.mat-coral:not(:disabled) {
        --mdc-filled-button-label-text-color: #ffffff;
        background: #FF8261;
    }
    &.mat-mdc-unelevated-button.mat-warn:not(:disabled) {
        --mdc-filled-button-label-text-color: #ffffff;
        background: mat.get-color-from-palette($bbo-md-warn-palette, 600);
    }
    &.mat-mdc-unelevated-button.mat-purple:disabled {
        --mdc-filled-button-disabled-label-text-color: #{mat.get-color-from-palette($bbo-md-gray-palette, default)} !important;
        --mdc-filled-button-label-text-color: #{mat.get-color-from-palette($bbo-md-gray-palette, default)} !important;
        --mdc-button-disabled-label-text-color: #{mat.get-color-from-palette($bbo-md-gray-palette, default)} !important;
    }
    &.mat-mdc-unelevated-button.mat-coral:disabled {
        --mdc-filled-button-disabled-label-text-color: #{mat.get-color-from-palette($bbo-md-gray-palette, default)} !important;
        --mdc-filled-button-label-text-color: #{mat.get-color-from-palette($bbo-md-gray-palette, default)} !important;
        --mdc-button-disabled-label-text-color: #{mat.get-color-from-palette($bbo-md-gray-palette, default)} !important;
    }
    &.bbo-small-button.mat-mdc-button:not(:disabled) {
        background: mat.get-color-from-palette($bbo-md-primary-palette, 700);
    }
    // end default state

    // disabled state
    --mdc-filled-button-disabled-label-text-color: #{map.get($button-disabled, "text-color")} !important;
    --mdc-filled-button-disabled-container-color: #{map.get($button-disabled, "background-color")} !important;
    --mdc-button-disabled-label-text-color: #{map.get($button-ghost-disabled, "text-color")} !important;
    --mdc-button-disabled-container-color: #{map.get($button-ghost-disabled, "background-color")} !important;
    // end disabled state
}

// target the internal border elements of the mdc field 
@mixin mdcInputFieldBorders() {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        @content
    }
}

.bbo-form-field {
    display: flex;
    flex-direction: column;

    $field-label-color: #737373;

    .bbo-form-label {
        margin-bottom: 4px;
        color: $field-label-color;
        @include mat.typography-level($my-custom-typography-config, 'body-2');
    }

    input {
        @include mat.typography-level($my-custom-typography-config, 'body-1');
    }

    --mdc-theme-primary: #2176FF;
    --mdc-theme-error: #FF3B44;
    $shadow-valid: 0px 0px 0px 3px #2176FF40;
    $shadow-invalid: 0px 0px 0px 3px #FF3B4440;

    $field-border-color-default-state: #B3B3B3; 

    // default state
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) {
        @include mdcInputFieldBorders() {
            border-color: $field-border-color-default-state;
        }
    }

    // hover on the field
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) {

        &:not(.mdc-text-field--invalid):hover {
            @include mdcInputFieldBorders() {
                border-color: var(--mdc-theme-primary); // mdc uses an hardcoded rgba color for that
                                                        // so let's override it wuth our color 
            }
        }

        &:hover {
            @include mdcInputFieldBorders() {
                border-width: 2px;  // by design mdc do not make the border wider on focus
            }
        }
    }

    // shadow around the field when it's focused
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused {
        box-shadow: $shadow-valid;

        &.mdc-text-field--invalid {
            box-shadow: $shadow-invalid;
        }

        border-radius: var(--mdc-shape-small); // use the same border-radius as children of .mdc-notched-outline
                                               // so that the box shadow has the same corner as the borders
    }
}

.mat-mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: #ffffff !important;
    --mdc-checkbox-selected-icon-color: #2176FF !important;
    --mdc-checkbox-selected-focus-icon-color: #2176FF !important;
    --mdc-checkbox-selected-hover-icon-color: #2176FF !important;
    --mdc-checkbox-selected-icon-color: #2176FF !important;
    --mdc-checkbox-selected-pressed-icon-color: #2176FF !important;

    .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
        background-color: #2176FF !important;
    }
}

.mat-mdc-icon-button {

    --mat-mdc-button-persistent-ripple-color: #99C0FF;
    --mat-mdc-button-ripple-color: #99C0FF;
    // remove background color for focus style
    &:focus-visible {
        --mat-mdc-button-persistent-ripple-color: transparent;
        --mat-mdc-button-ripple-color: transparent;
    }

    &:hover .mat-mdc-button-persistent-ripple{
        // special case for pagination button
        z-index: -1;

        &::before {
            opacity: 1;
        }
    }

    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-ripple {
        border-radius: 8px !important;
    }

    mat-icon {
        z-index: 1;
    }
}

.bbo-meta-form-field {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    flex-wrap: wrap;

    > .bbo-form-field {
        min-width: 10em;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 5em;
    }
}

// We make the height of the error message container bigger than the default height
// so that we show more characters
// and when a message is show, add a margin below the last one so that it's not
// to close to the next element
.bbo-form-field .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-subscript-dynamic-size  {
    min-height: 24px;

    :is(mat-error, mat-hint):last-of-type {
        margin-bottom: 12px; // margin below the error
    }
}
// because we add a margin in the rule above, we have to remove it when the hint or error
// is above a button so that the margin between elements is not too big
.error-above-button, .hint-above-button {
    margin-bottom: 0px !important;
}

.mat-mdc-form-field-error-wrapper, .mat-mdc-form-field-hint-wrapper {
    padding: 0 !important; // so that the message is the same width as the field
}

@include breakpoint.sm-down() {
    .mobile-hidden {
        display: none !important;
    }

    .mobile-svg-hidden {
        visibility: hidden;
        position: absolute;
    }
}

@include breakpoint.sm-up() {
    .desktop-hidden {
        display: none !important;
    }

    .desktop-svg-hidden {
        visibility: hidden;
        position: absolute;
    }
}

.no-ripple {
    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-ripple {
        display: none !important;
    }
}

.width-100 {
    width: 100%;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-semibold {
    font-weight: 600 !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-default {
    font-weight: 400 !important;
}

.text-center {
    text-align: center;
}

.divider-after::after {
    content: '';
    content: "";
    display: block;
    width: 70%;
    border-bottom: 1px solid rgba(mat.get-color-from-palette($bbo-store-client-gray, default), 30%);
    transform: translate(30%);
    margin-top: 12px;
    margin-bottom: 12px;
}

.bbo-backdrop-blurred {
    backdrop-filter: blur(3px);
}

.bbo-dialog {
    width: 100vw;
    overflow: auto;
    padding-top: 20vh;
    padding-bottom: 20vh;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint.sm-up() {
        padding-top: 12vh;
        padding-bottom: 12vh;
        padding-left: 15vw;
        padding-right: 15vw;
    }

    @include breakpoint.md-up() {
        padding-top: 12vh;
        padding-bottom: 12vh;
        padding-left: 13vw;
        padding-right: 13vw;
    }

    @include breakpoint.lg-up() {
        padding-top: 12vh;
        padding-bottom: 12vh;
        padding-left: 27.5vw;
        padding-right: 27.5vw;
    }

    &.visually-hidden {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
    }

    &.bbo-scroll-dialog {
        max-width: 100% !important;
        width: 100% !important;
    }
}

.bbo-dialog-content {
    --mdc-dialog-supporting-text-color: #191919;
    padding: 60px !important;
    max-height: none !important;

    .bbo-dialog-header {
        margin-bottom: 24px;
    }
}

mat-sidenav-container{
    position: unset;
}

// taken from https://cssloaders.github.io/
.bbo-3dots-loader, .bbo-3dots-loader:before, .bbo-3dots-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}
.bbo-3dots-loader {
    color: #FFF;
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}
.bbo-3dots-loader:before, .bbo-3dots-loader:after {
    content: '';
    position: absolute;
    top: 0;
}
.bbo-3dots-loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}
.bbo-3dots-loader:after {
    left: 3.5em;
}
@keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
}
.mdc-button > .bbo-3dots-loader {
    transform: translateY(-100%); // position the spinner at the vertical center
    font-size: 35%;
    position: absolute;

}
.mat-button-hide-label .mdc-button__label {
    visibility: hidden; // hide it but keep it in the flow so that the button keep its with
}


mat-stepper {
    height: 100%;

    .mat-horizontal-stepper-wrapper {
        height: 100%;

        .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
            height: 100%;
        }
    }
}

swiper-container {
    height: 100%;

    &::part(wrapper) {
        align-items: center;
        top: -28px; // visualy remove the shift coming from tab header padding value
        padding: 24px 0; // prevent cards shadow to be cropped
    }

    --swiper-scrollbar-drag-bg-color: #E5E5E5;
}

swiper-slide {
    width: 224px;
    $base-margin: 20px;
    height: fit-content;
    margin-top: $base-margin; // add margin so that shadow around cards is not cut off
    margin-bottom: $base-margin + 30px; // add even more margin at bottom to add space
                                        // between the slide content and the pagination container
    // we also set margin-left and margin-right for sildes with <swiper-container space-between="XX"
}

.mat-mdc-form-field-infix {
    display: flex;
}

.scroll-container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
}