// Breakpoints inspired from angular's layout breakpoints

// No media query for `xs` since this is the default

// Small devices (landscape phones, 600px and up)
@mixin sm-up() {
    @media (min-width: 600px) {
        @content;
    }
}

// Medium devices (tablets, 904px and up)
@mixin md-up() {
    @media (min-width: 904px) {
        @content;
    }
}

// Large devices (desktops, 1240px and up)
@mixin lg-up() {
    @media (min-width: 1240px) {
        @content;
    }
}

// X-Large devices (large desktops, 1440px and up)
@mixin xl-up() {
    @media (min-width: 1440px) {
        @content;
    }
}

// mainly used to hide element for mobile
@mixin sm-down() {
    @media (max-width: 599px) {
        @content;
    }
}

// mainly used to hide nav-bar elements in header
@mixin hide-nav-bar-header-down() {
    @media (max-width: 750px) {
        @content;
    }
}

// mainly used to hide navigation menu item on side menu
@mixin hide-nav-bar-header-up() {
    @media (min-width: 750px) {
        @content;
    }
}